<template>
    <div class="pd20x ofA">
        <overview :visible="openModel" @close="openModel = false" :type="overViewType"></overview>
        <a-row :gutter="20">
            <a-col class="gutter-row"  :xs="24" :sm="24" :md="8">
                <div class="gutter-box h340x" >
                    <div class="gutterTitle">
                        <div class="left">
                            <div class="icon"></div>
                            <div>数据变动</div>
                        </div>
                    </div>
                    <div class="dataChange">
                        <a-row type="flex" justify="space-between">
                            <a-col :span="10">
                                <div class="inner csP" @click="openOverView(0)">
                                    <div class="icon"></div>
                                    <div class="right">
                                        <div>今日访客(人)</div>
                                        <div class="txt">0</div>
                                    </div>
                                </div>
                            </a-col>
                            <a-col :span="10">
                                <div class="inner csP" @click="openOverView(1)">
                                    <div class="icon addperson"></div>
                                    <div class="right">
                                        <div>新增学员(人)</div>
                                        <div class="txt">0</div>
                                    </div>
                                </div>
                            </a-col>
                             <a-col :span="10">
                                <div class="inner csP"  @click="openOverView(2)">
                                    <div class="icon totalPerson"></div>
                                    <div class="right">
                                        <div>学员总数(人)</div>
                                        <div class="txt">0</div>
                                    </div>
                                </div>
                            </a-col>
                            <a-col :span="10">
                                <div class="inner csP"  @click="openOverView(3)">
                                    <div class="icon subjectNum"></div>
                                    <div class="right">
                                        <div>科目数量(条)</div>
                                        <div class="txt">0</div>
                                    </div>
                                </div>
                            </a-col>
                             <a-col :span="18">
                                <div class="inner csP"  @click="openOverView(4)">
                                    <div class="icon classNum"></div>
                                    <div class="right">
                                        <div>课程包数量(条)</div>
                                        <div class="txt">0</div>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </a-col>
            <a-col class="gutter-row"  :xs="24" :sm="24" :md="8">
                <div class="gutter-box h340x ofH">
                    <div class="gutterTitle">
                        <div class="left">
                            <div class="icon ready"></div>
                            <div>待办事项</div>
                        </div>
                        <div class="right csP" @click="routerChange('待办事项', '/exam/backlog')">
                            <a-icon type="right" />
                        </div>
                    </div>
                    <div class="ringWrap">
                        <Pie style="margin: 0 auto" :lengend="false" :chartdata="backlog"></Pie>
                        <div class="innderbox">
                            <div>待办事项（条）</div>
                            <div class="num">20</div>
                        </div>
                    </div>
                    <div class="ringBottom">
                        <a-row type="flex" justify="space-between">
                            <a-col :span="8">
                                <div class="inner">
                                    <div class="icon"></div>
                                    <div class="right">
                                        <div>建议上新</div>
                                        <div class="txt">0</div>
                                    </div>
                                </div>
                            </a-col>
                            <a-col :span="8">
                                <div class="inner">
                                    <div class="icon se"></div>
                                    <div class="right">
                                        <div>建议反馈</div>
                                        <div class="txt">0</div>
                                    </div>
                                </div>
                            </a-col>
                             <a-col :span="8">
                                <div class="inner">
                                    <div class="icon th"></div>
                                    <div class="right">
                                        <div>课程答疑</div>
                                        <div class="txt">0</div>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </a-col>
            <a-col class="gutter-row"  :xs="24" :sm="24" :md="8">
                <div class="gutter-box h340x ofH">
                    <div class="gutterTitle">
                        <div class="left">
                            <div class="icon th"></div>
                            <div>学习进度</div>
                        </div>
                        <div class="right picks">
                            <div class="pickerWrap">
                                <a-range-picker :value="time" @change="onChange" :placeholder="pickIndex?['开始日期', '结束日期']:['开始月','结束月']" :format="pickIndex?'YYYY-MM-DD':'YYYY-MM'" :mode="pickIndex?['date', 'date']:['month', 'month']" @panelChange="handlePanelChange"/>
                            </div>
                            <div class="pickBox" :class="{active: pickIndex == 0}" @click="pickIndex = 0">月</div>
                            <div class="pickBox" :class="{active: pickIndex == 1}" @click="pickIndex = 1">日</div>
                        </div>
                    </div>
                    <div style="height:260px">
                        <chartLine :yAxisName="['平均值%']" :chartdata="progressData"></chartLine>
                    </div>
                </div>
            </a-col>
        </a-row>
        <a-row :gutter="20">
            <a-col class="gutter-row"  :xs="24" :sm="24" :md="8">
                <div class="gutter-box h340x ofH">
                    <div class="gutterTitle">
                        <div class="left">
                            <div class="icon four"></div>
                            <div>学习记录</div>
                        </div>
                        <div class="right picks">
                            <div class="pickerWrap">
                                <a-range-picker :value="time" @change="onChange" :placeholder="pickIndex?['开始日期', '结束日期']:['开始月','结束月']" :format="pickIndex?'YYYY-MM-DD':'YYYY-MM'" :mode="pickIndex?['date', 'date']:['month', 'month']" @panelChange="handlePanelChange"/>
                            </div>
                            <div class="pickBox" :class="{active: pickIndex == 0}" @click="pickIndex = 0">月</div>
                            <div class="pickBox" :class="{active: pickIndex == 1}" @click="pickIndex = 1">日</div>
                        </div>
                    </div>
                    <div style="height:260px">
                        <chartLine :yAxisName="['学习时间']" :chartdata="recordData"></chartLine>
                    </div>
                </div>
            </a-col>
            <a-col class="gutter-row"  :xs="24" :sm="24" :md="16" >
                <div class="visit gutter-box h340x ofH">
                    <div class="">
                        <div class="gutterTitle">
                            <div class="left">
                                <div class="icon five"></div>
                                <div>访客分布</div>
                            </div>
                        </div>
                        <div class="left_bottom">
                            <div class="text">
                                <div>总访客（人）</div>
                                <div class="num">20</div>
                                <div>平均停留时间</div>
                                <div class="num">2′30</div>
                            </div>
                            <div class="ringWrap">
                                <Pie style="margin: 0 auto" :height="'250px'" :chartdata="visitData" :colors="['#ffc14e', '#f14057']"></Pie>
                                <div class="innderbox">
                                    <div>总访客（人）</div>
                                    <div class="num">20</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line">
                        <chartBar :chartdata="barData"></chartBar>
                    </div>
                </div>
            </a-col>
        </a-row>
        <div class="gutter-box h340x ofH">
            <div class="gutterTitle">
                <div class="left">
                    <div class="icon six"></div>
                    <div>收入管理</div>
                </div>
                <div class="right picks">
                    <div class="pickerWrap">
                        <a-range-picker :value="time" @change="onChange" :placeholder="pickIndex?['开始日期', '结束日期']:['开始月','结束月']" :format="pickIndex?'YYYY-MM-DD':'YYYY-MM'" :mode="pickIndex?['date', 'date']:['month', 'month']" @panelChange="handlePanelChange"/>
                    </div>
                    <div class="pickBox" :class="{active: pickIndex == 0}" @click="pickIndex = 0">月</div>
                    <div class="pickBox" :class="{active: pickIndex == 1}" @click="pickIndex = 1">日</div>
                </div>
            </div>
            <div class="left_bottom">
                <div class="text">
                    <div>总访客（人）</div>
                    <div class="num">20</div>
                    <div>平均停留时间</div>
                    <div class="num">2′30</div>
                </div>
                <div style="height:260px;flex:1">
                    <chartLine :yAxisName="['学习时间']" :chartdata="recordData"></chartLine>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import overview from '@/components/exam/overview.vue'
import Pie from '@/components/charts/pie'
import chartLine from '@/components/charts/chartLine'
import chartBar from '@/components/charts/chartBar'
import { routerlink } from '@/mixins/routerlink'
export default {
    name: 'exam',
    components: { Pie, chartLine, chartBar, overview },
    mixins: [routerlink],
    data(){
        return{
            pickIndex: 0,
            mode: ['month', 'month'],
            overViewType: 1,
            openModel: false,
            time: [],
            backlog:{
                columns: ['类型', '数据'],
                rows: [
                    { '类型': '建议上新', '数据': 5 },
                    { '类型': '建议反馈', '数据': 3 },
                    { '类型': '课程答疑', '数据': 12 },
                ]
            },
            progressData:{
                columns: ['日期', '学习进度'],
                rows: [
                    { '日期': '1.20', '学习进度': 5 },
                    { '日期': '1.21', '学习进度': 3 },
                    { '日期': '1.22', '学习进度': 12 },
                    { '日期': '1.23', '学习进度': 5 },
                    { '日期': '1.24', '学习进度': 3 },
                    { '日期': '1.25', '学习进度': 12 },
                    { '日期': '1.26', '学习进度': 5 },
                    { '日期': '1.27', '学习进度': 3 },
                    { '日期': '1.28', '学习进度': 12 },
                    { '日期': '1.29', '学习进度': 5 },
                    { '日期': '1.30', '学习进度': 3 },
                ]
            },
            recordData:{
                columns: ['日期', '学习进度'],
                rows: [
                    { '日期': '1.20', '学习进度': 5 },
                    { '日期': '1.21', '学习进度': 3 },
                    { '日期': '1.22', '学习进度': 12 },
                    { '日期': '1.23', '学习进度': 5 },
                    { '日期': '1.24', '学习进度': 3 },
                    { '日期': '1.25', '学习进度': 12 },
                    { '日期': '1.26', '学习进度': 5 },
                    { '日期': '1.27', '学习进度': 3 },
                    { '日期': '1.28', '学习进度': 12 },
                    { '日期': '1.29', '学习进度': 5 },
                    { '日期': '1.30', '学习进度': 3 },
                ]
            },
            visitData:{
                columns: ['类型', '数据'],
                rows: [
                    { '类型': '江苏', '数据': 5 },
                    { '类型': '外省', '数据': 3 },
                ]
            },
            barData:{
                columns: ['地址', '时间'],
                rows: [
                    { '地址': '1/1', '时间': 2 },
                    { '地址': '1/2', '时间': 3 },
                    { '地址': '1/3', '时间': 4 },
                    { '地址': '1/4', '时间': 5 },
                    { '地址': '1/5', '时间': 6 },
                    { '地址': '1/6', '时间': 6 }
                ]
            }
        }
    },
    methods:{
        onChange(value){
            this.time = value
        },
        handlePanelChange(val, mode){
           this.time = val
           this.mode = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
        },
        openOverView(type){
            this.overViewType = type
            this.openModel = true
        }
    },
};
</script>
<style lang="less" scoped>
.gutterTitle{
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
        display: flex;
        align-items: center;
        .icon{
            width: 22px;
            height: 22px;
            border-radius: 100%;
            margin-right: 9px;
            overflow: hidden;
            background: url(../../../public/image/indexPng.png);
            background-position: -3px -2px;
            &.ready{
                background-position: -36px -2px;
            }
            &.th{
                background-position: -71px -2px;
            }
            &.four{
                background-position: -3px -31px;
            }
            &.five{
                background-position: -36px -31px;
            }
            &.six{
                background-position: -71px -31px;
            }
        }
    }
}
.dataChange{
    margin-top: 15px;
    padding: 0 30px;
    .inner{
        margin-bottom: 25px;
        >div{
            display: inline-block;
            vertical-align: top;
            .txt{
                color: #ed4650;
                font-weight: 600;
                font-size: 20px;
                line-height: 1;
                margin-top: 15px;
                padding-left: 30px;
            }
        }
        .icon{
            width: 25px;
            height: 25px;
            border-radius: 100%;
            margin-right: 14px;
            background: url(../../../public/image/dataChange.png);
            background-position: -2px -2px;
            &.addperson{
                background-position: -37px -2px;
            }
            &.totalPerson{
                background-position: -72px -2px;
            }
            &.subjectNum{
                background-position: -2px -29px;
            }
            &.classNum{
                background-position: -37px -29px;
            }
        }
    }
}
.ringWrap{
    position: relative;
    height: 200px;
    .innderbox{
        position: absolute;
        width: 100px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        top: 50px;
        left: 50%;
        margin-left: -45px;
        color: #888888;
        .num{
            font-size: 16px;
            color: #333;
            font-weight: 600;
            margin-top: 10px;
        }
    }
}
.ringBottom{
    padding: 0 20px;
    margin-top: 15px;
    text-align: center;
    .inner>div{
        display: inline-block;
        vertical-align: top;
    }
    .icon{
        width: 10px;
        height: 10px;
        background: #f14057;
        border-radius: 2px;
        margin-right: 8px;
        margin-top: 2px;
        &.se{
            background: #70b2e2;
        }
        &.th{
            background: #ffc14e;
        }
    }
    .right{
        line-height: 1;
        .txt{
            font-size: 20px;
            color: #f14057;
            margin-top: 15px;
        }
    }
}
.pickerWrap{
    width: 220px;
    /deep/.ant-input{
        border-radius: 32px;
        border: none;
        background: #fafafa;
    }
}
.picks{
    display: flex;
    align-items: center;
    .pickBox{
        cursor: pointer;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        border-radius: 25px;
        border: 1px solid #efefef;
        margin-left: 18px;
        color: #ff5353;
        &.active{
            border: none;
            background: #ffa9a9;
            color: #fff;
        }
    }
}
.visit{
    display: flex;
    width: 100%;
    .line{
        flex: 1;
        height: 340px;
    }

}
.left_bottom{
        display: flex;
        align-items: center;
        .text{
            padding-left: 20px;
            text-align: center;
            white-space: nowrap;
            .num{
                margin-top: 15px;
                margin-bottom: 30px;
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
</style>
